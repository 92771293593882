import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { InfoService } from '../../services/info.service';
import { Constants } from '../entities/Constants';
import { JwtPermissions } from '../jwtPermissions.service';

export const authGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  const router = inject(Router);
  const infoService = inject(InfoService);

  const currentUrl = router.url;

  const blockedRoutes = [
    `/${Constants.LINK_SIGNUP}`,
    `/${Constants.LINK_SIGNUP}/${Constants.LINK_SIGNUP_BUYER}`,
    `/${Constants.LINK_SIGNUP}/${Constants.LINK_SIGNUP_PUBLISHER}`,
    `/${Constants.LINK_INVITATION}`,
    `/${Constants.LINK_PASS_RECOVERY}`
  ];

  const isBlocked =
    blockedRoutes.includes(currentUrl) ||
    currentUrl.startsWith(`/${Constants.LINK_INVITE_PUBLISHER}`) ||
    currentUrl.startsWith(`/${Constants.LINK_INVITE_GOOGLE_PUBLISHER}`) ||
    currentUrl.startsWith(`/${Constants.LINK_INVITE_ADDITIONAL_USER}`);

  if (infoService.elements && isBlocked) {
    return false;
  }

  if (JwtPermissions.api) {
    return true;
  }

  router.navigate([`/${Constants.LINK_LOGIN}`], { queryParams: { returnUrl: state.url } });
  return false;
};
