import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputMaskModule} from 'primeng/inputmask';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {ListboxModule} from 'primeng/listbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PasswordModule} from 'primeng/password';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TooltipModule} from 'primeng/tooltip';
import {UploadPrimeComponent} from './_sharedMVC/uploadPrime/uploadPrime.component';
import {TranslateModule} from '@ngx-translate/core';
import {ClipboardModule} from 'ngx-clipboard';
import {LoginService} from './services/login.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingPageComponent} from './_sharedMVC/loadingPage/loadingPage.component';
import {SidebarComponent} from './_sharedMVC/sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {NotifyComponent} from './_sharedMVC/notify/notify.component';
import {HttpClientModule} from '@angular/common/http';
import {PageService} from './services/page.service';
import {ToastModule} from 'primeng/toast';
import {TableService} from './services/table.service';
import {SafeHtmlPipe} from './__helpers/pipes/safeHtml.pipe';
import {IncudesElPipe} from './__helpers/pipes/incudesEl.pipe';
import {NumberFormatPipe} from './__helpers/pipes/number.pipe';
import {MinusIfEmptyPipe} from './__helpers/pipes/minusIfEmpty.pipe';
import { ContainsUrlPipe } from './__helpers/pipes/containsUrl.pipe';
import { UserService } from './services/user.service';
import { EntityService } from './services/entityService';
import {UploadMultiComponent} from './_billingMVC/uploadMulti/uploadMulti.component';
import {CarouselModule} from 'primeng/carousel';
import { ZeroIfEmptyPipe } from './__helpers/pipes/zeroIfEmpty.pipe';
import { NumberReduxPipe } from './__helpers/pipes/numberRedux.pipe';
import {SanitizerPipe} from './__helpers/pipes/sanitizer.pipe';
import {UploadMultiBodyComponent} from './_billingMVC/uploadMultiBody/uploadMultiBody.component';
import {SidePanelModule} from './_sharedMVC/sidePanel/sidePanel.module';
import {GetElBySpecifiedValuePipe} from './__helpers/pipes/getElBySpecifiedValue.pipe';
import {GetElValuePipe} from './__helpers/pipes/getElValue.pipe';
import {CommunicationBarComponent} from './_sharedMVC/communicationBarPanel/communicationBar/communicationBar.component';
import {CommunicationBarService} from './_sharedMVC/communicationBarPanel/communicationBar.service';
import {FirstIfNotEmptyPipe} from './_sharedMVC/communicationBarPanel/_helpers/firstIfNotEmpty.pipe';
import { GoogleMapsModule } from '@angular/google-maps'
import {BooleanCheckboxComponent} from './__components/booleanCheckbox/booleanCheckbox.component';
import {BooleanInputSwitchComponent} from './__components/booleanInputSwitch/booleanInputSwitch.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CarouselModule,
    CheckboxModule,
    ClipboardModule,
    CommonModule,
    DialogModule,
    DragDropModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    InputTextModule,
    InputSwitchModule,
    InputTextareaModule,
    InputMaskModule,
    ListboxModule,
    MultiSelectModule,
    OverlayPanelModule,
    PasswordModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RouterModule,
    TooltipModule,
    SidePanelModule,
    TranslateModule,
    GoogleMapsModule
  ],
    declarations: [
      FirstIfNotEmptyPipe,
      GetElBySpecifiedValuePipe,
      NumberFormatPipe,
      NumberReduxPipe,
      MinusIfEmptyPipe,
      ZeroIfEmptyPipe,
      ContainsUrlPipe,
      IncudesElPipe,
      SanitizerPipe,
      GetElValuePipe,
      CommunicationBarComponent,
      LoadingPageComponent,
      NotifyComponent,
      SidebarComponent,
      UploadPrimeComponent,
      UploadMultiComponent,
      UploadMultiBodyComponent,
      BooleanCheckboxComponent,
      BooleanInputSwitchComponent,
      SafeHtmlPipe
    ],
  providers: [
    LoginService,
    CommunicationBarService,
    PageService,
    TableService,
    UserService,
    EntityService
  ],
  exports: [
    CommunicationBarComponent,
    InputSwitchModule,
    LoadingPageComponent,
    UploadPrimeComponent,
    UploadMultiComponent,
    UploadMultiBodyComponent,
    IncudesElPipe,
    SanitizerPipe,
    GetElBySpecifiedValuePipe,
    GetElValuePipe,
    ContainsUrlPipe,
    DialogModule,

    SidePanelModule,
    CheckboxModule,
    ClipboardModule,
    DropdownModule,
    FormsModule,
    HttpClientModule,
    InputMaskModule,
    InputTextareaModule,
    InputTextModule,
    ListboxModule,
    MultiSelectModule,
    NotifyComponent,
    OverlayPanelModule,
    PasswordModule,
    RadioButtonModule,
    ReactiveFormsModule,
    SidebarComponent,
    ToastModule,
    TooltipModule,
    TranslateModule,
    SafeHtmlPipe,
    NumberFormatPipe,
    MinusIfEmptyPipe,
    ZeroIfEmptyPipe,
    NumberReduxPipe,
    GoogleMapsModule,
    BooleanCheckboxComponent,
    BooleanInputSwitchComponent
  ]
})
export class SharedThingsModule { }
