export class ChartUtils {

  static readonly colorsPie = ['#adf7c4', '#85edba', '#52e0ad', '#0acf9c', '#00c48a', '#1fad7a', '#339966'];
  static readonly colorsPieDevice = ['rgb(181, 213, 230)', '#0076d4', 'rgb(63, 152, 208)'];

  static readonly colors7Days = ['#adf7c4', '#85edba', '#52e0ad', '#0acf9c', '#00c48a', '#1fad7a', '#339966'];
  static readonly colorsImpression7Days = ['rgb(202, 225, 233)', 'rgb(157, 207, 218)', 'rgb(155, 192, 222)', 'rgb(106, 166, 214)', '#238fcc', '#337ab7', '#2b5571'];

  static readonly colors30Days = ['#ccffcc', '#c2fcc9', '#b8fac7', '#adf7c4', '#a3f5c2', '#99f2bf', '#8ff0bd', '#85edba', '#7aebb8', '#70e8b5', '#66e6b2', '#5ce3b0', '#52e0ad', '#47deab', '#3ddba8', '#33d9a6', '#29d6a3', '#0acf9c', '#00cc99', '#00c994', '#00c48a', '#00c285', '#00bf80', '#00bd7a', '#00b870', '#1fad7a', '#21ab78', '#26a673', '#2ba16e', '#2e9e6b', '#309c69', '#339966'];
  static readonly colorsImpression30Days = ['#CFD4D6', '#CED6D9', '#CDD8DC', '#CCDADF', '#CCDDE3', '#CBDFE6', '#CAE1E9', '#B6D7E5', '#B5D5E6', '#B5D3E6', '#B5D1E7', '#A6C8E4', '#97C0E0', '#88B7DD', '#79AFD9', '#6AA6D6', '#5CA1D4', '#4E9DD2', '#3F98D0', '#3194CE', '#238FCC', '#268CC9', '#2888C5', '#2B85C2', '#2E81BE', '#307EBB', '#337AB7', '#3275AD', '#316FA3', '#306A99', '#2E658F', '#2D6085', '#2C5A7B', '#2B5571'];
}
