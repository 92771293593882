import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {GlobalTargetingComponent} from '../_rulesMVC/globalTargeting/globalTargeting.component';
import {NewCampaignsComponent} from '../_campaignMVC/_newCampaignMVC/newCampaigns/newCampaigns.component';
import {GlobalTargetingPublisherComponent} from '../_rulesMVC/globalTargetingPublisher/globalTargetingPublisher.component';
import {CerCampaignComponent} from '../_cerberusMVC/cerberusBuyer/mainCampaign/cerCampaign.component';

@Injectable()
export class CanDeactivateGuard  {

  canDeactivate(
    component: NewCampaignsComponent | GlobalTargetingComponent |
      GlobalTargetingPublisherComponent | CerCampaignComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    // Otherwise ask the user with the dialog service and return its
    // observable which resolves to true or false when the user decides
    return component.canDeactivate();
  }
}
