import { Pipe, PipeTransform } from '@angular/core';
import {UtilsFun} from '../entities/UtilsFun';

@Pipe({
  name: 'findEntityType'
})
export class GetEntityByTypePipe implements PipeTransform {
  transform(id: string|number): {id: number, name: string, color: string} {
    return UtilsFun.findEntityType(id);
  }
}

