import {Injectable} from '@angular/core';
import {GlobalSettings} from '../../GlobalSettings';
import {Observable} from 'rxjs';
import {ErrorManager} from '../__helpers/errorManager';
import {HttpClient} from '@angular/common/http';
import {Bills} from '../__helpers/entities/Bills';
import {BillPublisher} from './_helpers/BillPublisher';
import {PaymentDetails} from '../__helpers/entities/PaymentDetails';
import {BillNew} from '../__helpers/entities/BillNew';
import {SelectItem} from 'primeng/api';
import {CerFun} from '../_cerberusMVC/cerberusUtility/cerFun';
import {BillingIO} from './_helpers/billingIO';
import {CerCampaign} from '../_cerberusMVC/cerberusBuyer/cerEntity/CerCampaign';
import {BillingIoEnum} from './billingAdmin/buyerCollection/billingInsertionOrder/billingIoEnum';
import {SelectItemType} from '../__helpers/entities/SelectItemType';
import {EntityAccount} from '../__helpers/entities/EntityAccount';
import {DestinationDetails} from './_helpers/DestinationDetails';
import {TaxInformation} from '../__helpers/entities/TaxInformation';

@Injectable()
export class BillingService {

  public static readonly wiseAndXeroExceptions: SelectItem[] = [
    {label: 'All', value: 0},
    {label: 'Only Xero error', value: 1},
    {label: 'Only Wise error', value: 2},
    {label: 'Only in error (Wise or Xero)', value: 3},
  ];

  /******************************************************** Url Bill Publisher New Call *********************************************/
  private readonly publisherBillURL = GlobalSettings.APIurl + '/api/v1/billing/publisher';
  private readonly publisherBillURLV2 = GlobalSettings.APIV2url + '/v2/admin/publisher/billing';
  private readonly getBillingSubpublisherURL = GlobalSettings.APIurl + '/api/v1/billing/publisher/getBillingSubpublisher';
  private readonly invoiceProfileURL = GlobalSettings.APIurl + '/api/v1/billing/invoiceProfile';
  private readonly notesBillURL = this.publisherBillURL + '/notes';
  private readonly getBillingDeadlineURL = this.publisherBillURL + '/deadline';
  private readonly getAddressDetailURL = this.publisherBillURL + '/detail';
  private readonly acceptBillsURL = this.publisherBillURL + '/acceptance';
  private readonly saveBillURL = this.publisherBillURL + '/upload';
  private readonly saveBankURL = this.publisherBillURL + '/:id/bank/:bank';

  /******************************************************** Wise/Barclays Export Call *********************************************/
  private readonly generateBankZipURL = GlobalSettings.APIurl + '/api/v1/bank-integrations/deadline-export';

  /******************************************************** Url IO Call *********************************************/
  private readonly billingUpdateURL = GlobalSettings.APIurl + '/api/v1/billing';
  private readonly billingInsertionOrderURL = GlobalSettings.APIurl + '/api/v1/billing/buyer';
  private readonly billingIoFeeURL = GlobalSettings.APIurl + '/api/v1/billing/buyer/feeBreakdown';
  private readonly billingCsvURL = GlobalSettings.APIV2url + '/v2/admin/publisher/billing/file/download';
  private readonly taxInformationURL = GlobalSettings.APIV2url + '/v2/admin/publisher/billing/tax-infos';

  /******************************************************** Url Bill Call *********************************************/
  private readonly getBuyerBillingURL = GlobalSettings.APIurl + '/api/v1/billing/buyer/getBuyerBilling';

  public static haveToShowPaymentProfileAlert(entityInfo: EntityAccount): boolean {
    return entityInfo && entityInfo.autoinvoice && !entityInfo.wiseRecipient && entityInfo.bank === DestinationDetails.billingBankWISE;
  }

  constructor(private http: HttpClient) {}

  /****************************************************** Bill ********************************************************/

  getBillingPublisher(stat: number[], publ: number, month: string, selectedException: number, selectedBank: string): Observable<BillPublisher[]> {
    const params: any = {};
    if (stat && stat.length > 0) { params['status'] = stat.toString(); }
    if (!CerFun.isVoid(publ)) { params['publisher'] = publ.toString(); }
    if (!CerFun.isVoid(month)) { params['month'] = month.toString(); }
    if (!CerFun.isVoid(selectedException)) { params['selectedException'] = selectedException.toString(); }
    if (!CerFun.isVoid(selectedBank)) { params['bank'] = selectedBank; }
    return this.http.get<BillPublisher[]>(this.publisherBillURL, {params: params}).catch(ErrorManager.handleError);
  }

  getBillingPublisherV2(stat: number[], publ: number, month: string, selectedException: number, selectedBank: string): Observable<BillPublisher[]> {
    const params: any = {};
    if (stat && stat.length > 0) { params['status'] = stat.toString(); }
    if (!CerFun.isVoid(publ)) { params['publisher'] = publ.toString(); }
    if (!CerFun.isVoid(month)) { params['month'] = month.toString(); }
    if (!CerFun.isVoid(selectedException)) { params['selectedException'] = selectedException.toString(); }
    if (!CerFun.isVoid(selectedBank)) { params['bank'] = selectedBank; }
    return this.http.get<BillPublisher[]>(this.publisherBillURLV2, {params: params}).catch(ErrorManager.handleError);
  }

  getBillingDeadline(stat: number, publ: number, deadline: string, network: number, selectedException: number, viewAll: boolean, selectedBank: string): Observable<any[]> {
    const params: any = {};
    if (!CerFun.isVoid(stat)) { params['status'] = stat.toString(); }
    if (!CerFun.isVoid(publ)) { params['publisher'] = publ.toString(); }
    if (!CerFun.isVoid(deadline)) { params['deadline'] = deadline.toString(); }
    if (!CerFun.isVoid(network)) { params['network'] = network.toString(); }
    if (viewAll) { params['viewAll'] = viewAll; }
    if (!CerFun.isVoid(selectedException)) { params['selectedException'] = selectedException.toString(); }
    if (!CerFun.isVoid(selectedBank)) { params['bank'] = selectedBank; }
    return this.http.get<any[]>(this.getBillingDeadlineURL, {params: params}).catch(ErrorManager.handleError);
  }

  getAddressDetails(): Observable<PaymentDetails> {
    return this.http.get<PaymentDetails>(this.getAddressDetailURL).catch(ErrorManager.handleError);
  }

  saveBill(id: number, mydocument: string[]): Observable<any> {
    return this.http.post(this.saveBillURL, {id: id, filenameList: mydocument}).catch(ErrorManager.handleError);
  }

  changeStatusBill(id: number, entity_id, status: number): Observable<BillNew> {
    return this.http.post<Bills>(this.publisherBillURL, {id: id, entity_id: entity_id, status: status}).catch(ErrorManager.handleError);
  }

  modifyBillingNew(id: number, gross: number, myCurrency: string): Observable<any> {
    const el: any = {id: id, value: gross, currency: myCurrency};
    return this.http.put(this.publisherBillURLV2, el).catch(ErrorManager.handleError);
  }

  saveBillingNotes(id: number, myNotes: string): Observable<any> {
    const el: any = {id: id, billingNotes: myNotes};
    return this.http.put(this.notesBillURL, el).catch(ErrorManager.handleError);
  }

  acceptBills(ids: number[]): Observable<any> {
    return this.http.post(this.acceptBillsURL, {ids: ids}).catch(ErrorManager.handleError);
  }

  saveBillBank(id: number, bank: string): Observable<any> {
    return this.http.put(this.saveBankURL.replace(':id', id.toString()).replace(':bank', bank), {}).catch(ErrorManager.handleError);
  }

  /****************************************************** Bill Buyer ********************************************************/

  getBuyerBilling(stat: number, invoiceProfile: SelectItemType, month: string): Observable<any[]> {
    const params: any = {};
    if (!CerFun.isVoid(stat)) { params['status'] = stat.toString(); }
    if (!CerFun.isVoid(month)) { params['month'] = month.toString(); }
    if (!CerFun.isVoid(invoiceProfile)) {
      if (!CerFun.isVoid(invoiceProfile.value)) { params['invoice'] = invoiceProfile.value}
      if (!CerFun.isVoid(invoiceProfile.type)) { params['type'] = invoiceProfile.type}
    }
    return this.http.get<any[]>(this.getBuyerBillingURL, {params: params}).catch(ErrorManager.handleError);
  }

  getBankZip(deadline: string): Observable<any> {
    const params: any = {};
    if (deadline !== undefined) { params['deadline'] = deadline.toString(); }
    return this.http.get(this.generateBankZipURL, {params: params, responseType: 'blob'}).catch(ErrorManager.handleError);
  }

  autoinvoiceConditionsOk(bill: BillNew) {
    const month = parseInt(bill.month.slice(0, 2), 10);
    const year = parseInt(bill.month.slice(3, 7), 10);

    return bill.autoinvoice === 0 || (month < 9 && year <= 2021);
  }

  /**
   * Type:
   * public static final Integer AGENCY = 1;
   * public static final Integer INVOICE_PROFILE = 2;
   * public static final Integer BUYER_SELF = 3;
   */
  getInvoiceProfiles(): Observable<SelectItemType[]> {
    return this.http.get<SelectItemType[]>(this.invoiceProfileURL);
  }

  /***********************************----** Insertion Order Billing ****************************************/
  getBillingInsertionOrder(currentPeriod: string, status: number, invoiceProfile: SelectItemType, advertisers: number[], selectedEntity: number, self: BillingIoEnum): Observable<BillingIO[]> {
    const params: any = {};
    if (!CerFun.isVoid(currentPeriod)) { params['month'] = currentPeriod.toString(); }
    if (!CerFun.isVoid(status)) { params['status'] = status.toString(); }
    if (invoiceProfile !== undefined) {
      if (!CerFun.isVoid(invoiceProfile.value)) { params['invoice'] = invoiceProfile.value}
      if (!CerFun.isVoid(invoiceProfile.type)) { params['invoiceType'] = invoiceProfile.type}
    }
    if (!CerFun.isVoidArray(advertisers)) { params['advertiser'] = advertisers.toString(); }
    if (!CerFun.isVoid(selectedEntity)) { params['idEntity'] = selectedEntity.toString(); }
    if (!CerFun.isVoid(self)) { params['self'] = self.toString(); }
    return this.http.get<BillingIO[]>(this.billingInsertionOrderURL, {params: params});
  }

  modifyBillIoValue(billingId: number, fieldCol: number, newValue: number): Observable<any> {
    const params: any = {};
    if (!CerFun.isVoid(billingId)) { params['billingId'] = billingId.toString(); }
    if (!CerFun.isVoid(fieldCol)) { params['field'] = fieldCol.toString(); }
    if (!CerFun.isVoid(newValue)) { params['value'] = newValue.toString(); }
    return this.http.put(this.billingInsertionOrderURL, undefined, {params: params}).catch(ErrorManager.handleError);
  }

  billingUpdate(invoiceId: number, invoiceType: number, month: string, status: number, filenameList: string): Observable<any> {
    const params: any = {};
    if (!CerFun.isVoid(invoiceId)) { params['invoiceId'] = invoiceId.toString(); }
    if (!CerFun.isVoid(invoiceType)) { params['invoiceType'] = invoiceType.toString(); }
    if (!CerFun.isVoid(month)) { params['month'] = month.toString(); }
    if (!CerFun.isVoid(status)) { params['status'] = status.toString(); }
    if (!CerFun.isVoid(filenameList)) { params['fileParam'] = filenameList; }
    return this.http.post(this.billingUpdateURL, undefined, {params: params});
  }

  getBillingIoFee(billingId: number): Observable<CerCampaign> {
    const params: any = {};
    if (!CerFun.isVoid(billingId)) { params['billingId'] = billingId.toString(); }
    return this.http.get<CerCampaign>(this.billingIoFeeURL, {params: params});
  }

  getCsv(selectedDate: string): Observable<any> {
    const params: any = {};
    params['period'] = selectedDate;
    return this.http.get(this.billingCsvURL, {responseType: 'blob', params: params});
  }

  getTaxInformation(taxInfoId: number): Observable<TaxInformation> {
    return this.http.get<TaxInformation>(this.taxInformationURL + '/' + taxInfoId.toString());
  }
}
