import {Injectable} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {InfoService} from './info.service';
import {Constants} from '../__helpers/entities/Constants';
import {DataGenerateTable} from '../__helpers/entities/DataGenerateTable';
import {TranslateService} from '@ngx-translate/core';
import {Filter} from '../__helpers/entities/Filter';
import {Log} from '../__helpers/entities/Campaign';
import {Observable} from 'rxjs/Observable';
import {GlobalSettings} from '../../GlobalSettings';
import * as EventBus from '@vertx/eventbus-bridge-client.js/vertx-eventbus';
import {UtilsFun} from '../__helpers/entities/UtilsFun';
import {CampaignBlocked} from '../__helpers/entities/CampaignBlocked';
import {Column} from '../__helpers/entities/Column';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/catch';
import {EventsIds} from '../__helpers/entities/EventsIds';
import {DateFun} from '../__helpers/dates/DateFun';
import {MetricsIds} from '../__helpers/entities/MetricsIds';
import {ColumnValue} from '../__helpers/entities/ColumnValue';
import {ChartData} from 'chart.js';
import {ChartUtils} from '../_homeMVC/_helpers/ChartUtils';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  public readonly WEEK_LABELS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  eventBus: EventBus;
  loadEvent = false; // false se sto caricando l'eventbus
  blockedCampaigns: CampaignBlocked[] = []; // Array contenente le campagne bloccate (nel caso sta caricando delle modifiche)

  // dashboard data
  dashboardTodayData: number;
  dashboardTodayImpressionData: number;
  dashboardYesterdayData: number;
  dashboardCurrentMonthData: number;
  dashboardPastMonthData: number;

// Publisher Chart
  public dashboard7daysNewGraphData: ChartData;
  public dashboard7daysImpressionGraphData: ChartData;

// Buyer Chart
  public dashboard30daysNewGraphData: ChartData;
  public dashboard30daysImpressionNewGraphData: ChartData;
  public dashboardPieTopCampaignGraphData: ChartData;
  public dashboardPieDeviceGraphData: ChartData;

  dashboardPieTopDomainGraphData;
  topCampaignData;
  topDomainData;

  // Curation
  public dashboardCurationSpendData: ColumnValue;
  public dashboardCurationEarningsData: ColumnValue;
  public dashboardCurationMedianCPMData: ColumnValue;
  public dashboardCurationViewabilityData: ColumnValue;

  // Curation Chart
  public dashboardCurationCurrentMonthGraphData: ChartData<'bar'>;
  public dashboardCurationPieDPData: ChartData<'doughnut'>;
  public dashboardCurationPieAdFormatData: ChartData<'doughnut'>;

  // Report Structures to send
  reportPast: DataGenerateTable;
  reportTodayData: DataGenerateTable;
  reportTopDomain: DataGenerateTable;
  reportTopCampaign: DataGenerateTable;
  reportDevice: DataGenerateTable;
  reportCurator: DataGenerateTable;

  isPublisherNoDomains = false; // true if is a publisher without domains

  domainsNoTagged: boolean;

  expectedReport = {};

  todayDate = new Date();
  today: string;

  myMoneyColumn: Column;
  myImpressionColumn: Column;

  timerWaitReport: any[] = [];

  /* URL API */

  private readonly getLogsURL = GlobalSettings.APIurl + '/api/manager/getLogs';

  constructor(private infoService: InfoService, public translate: TranslateService, private http: HttpClient) {

    const options = {
      vertxbus_reconnect_attempts_max: Infinity, // Max reconnect attempts
      vertxbus_reconnect_delay_min: 1000, // Initial delay (in ms) before first reconnect attempt
      vertxbus_reconnect_delay_max: 5000, // Max delay (in ms) between reconnect attempts
      vertxbus_reconnect_exponent: 2, // Exponential backoff factor
      vertxbus_randomization_factor: 0.5 // Randomization factor between 0 and 1
    };

    this.eventBus = new EventBus( GlobalSettings.APIurl + '/eventbus');
    this.eventBus.enableReconnect(true, options);

    this.loadEvent = false;
    this.eventBus.onopen = () => {
      this.loadEvent = true;

      this.infoService.getUserGettedInObs().subscribe((cond2) => {
        if (cond2) {
          this.eventBus.registerHandler('lockCampaign.' + this.infoService.user.entityId, (error, message) => {
            if (message && message.body) {
              const temp = JSON.parse(message.body) as CampaignBlocked;
              console.log(temp);
              if (temp) {
                if (temp.lock === 1) {
                  this.blockedCampaigns.push(temp);
                } else {
                  this.blockedCampaigns = this.blockedCampaigns.filter(elem => elem.idCampaign !== temp.idCampaign);
                }
              }
            }
          });
        }
      });
    };
    this.todayDate = new Date();
    this.today = this.todayDate.toLocaleDateString('en-GB');
  }

  /**
   * @param selected Report da generare
   * @param isLabel 1 se mando gli id nei groupBy invece del nome (es Campaign: 342), 2 inibisce il rename degli eventi per semplificare il mapping
   * @param type tipo di report (campagne, guadagni...)
   * @param noFormatting se voglio il dato monetario non formattato
   * @param requiresTotal se voglio il totale
   */
  getDashboardReport(selected: DataGenerateTable, isLabel: string, type?: string, noFormatting?: string, requiresTotal?: boolean): any {
    const params: any = {};
    params['requireTotal'] = requiresTotal ? '1' : '0';
    if (type) {
      params['type'] = type;
    } else if (this.infoService.user.type === Constants.BUYER) {
      params['type'] = 'campaign'
    } else if (this.infoService.user.type === Constants.PUBLISHER) {
      params['type'] = 'revenue';
    }
    if (isLabel) {
      params['nolabel'] = isLabel;
    }
    if (noFormatting !== undefined) {
      params['noFormatting'] = noFormatting;
    }
    params['lang'] = InfoService.getLanguage(this.infoService.translate.currentLang).toString();
    return this.http.post(this.infoService.getReportURL, selected, {params: params}).subscribe();
  }

  getFirstPreviousMonth(dateObj: Date) {
    const tempDateObj = dateObj;
    if (tempDateObj.getMonth) {
      tempDateObj.setMonth(tempDateObj.getMonth() - 1, tempDateObj.getDay());
    } else {
      tempDateObj.setFullYear(tempDateObj.getFullYear() - 1);
      tempDateObj.setMonth(12);
    }
    tempDateObj.setDate(1);
    return UtilsFun.pad(tempDateObj.getDate()) + '/' + UtilsFun.pad(tempDateObj.getMonth() + 1) + '/' + tempDateObj.getFullYear();
  };

  getBuyerDashboardData() {
    this.infoService.getUserGettedInObs().subscribe((cond) => {
      if (cond) {
        this.columnLoader();
        this.getPeriodReports();
        this.getPieReport();

        // top 5 campaign
        this.reportTopCampaign = this.populateReportDashboard(Constants.LAST_7_DAYS);
        this.reportTopCampaign.groupBy = [10];
        this.waitReport(this.reportTopCampaign, 'reportCampaign', this.manageTopCampaign);
        this.getDashboardReport(this.reportTopCampaign, '0', undefined, '1');
      }
    });
    return true;
  }

  getPublisherDashboardData(periodId: number) {
    this.infoService.getUserGettedInObs().subscribe((cond) => {
      if (cond) {
        this.columnLoader();
        this.getPeriodReports();
        this.getDomainsDashboardData(periodId);
      }
    });
    return true;
  }

  getCuratorDashboardData() {
    this.infoService.getUserGettedInObs().subscribe((cond) => {
      if (cond) {
        this.getCuratorReports();
        this.getCuratorReportsPieDP();
        this.getCuratorReportsPieFormat();
      }
    });
    return true;
  }

  getDomainsDashboardData(periodId: number) {
    // top 5 domini
    this.reportTopDomain = this.populateReportDashboard(periodId);
    this.reportTopDomain.groupBy = [4];
    this.waitReport(this.reportTopDomain, 'reportDomain', this.manageTopDomain);
    this.getDashboardReport(this.reportTopDomain, '0', undefined, '1');
  }

  getPeriodReports() {
    // Past Report
    const lastDay = new Date(new Date().setDate(new Date().getDate() - 1 )).toLocaleDateString('en-GB');
    this.reportPast = this.populateReportDashboard(Constants.SELECT_PERIOD, this.getFirstPreviousMonth(new Date()), lastDay);
    this.waitReport(this.reportPast, 'reportPast', this.manageReportPast);
    this.getDashboardReport(this.reportPast, '0', undefined, '1');

    // Today Report
    this.reportTodayData = this.populateReportDashboard(Constants.TODAY);
    this.waitReport(this.reportTodayData, 'reportToday', this.manageTodayData);
    this.getDashboardReport(this.reportTodayData, '0', undefined, '1');
  }

  getCuratorReports() {
    // Current Month
    const cols: Column[] = [
        {id: EventsIds.CURRENCY_DEAL_SPEND, type: 'EVENT'},
        {id: EventsIds.CURRENCY_REVENUE_SHARE, type: 'EVENT'},
        {id: MetricsIds.CURRENCY_AVERAGE_CPM, type: 'METRIC'},
        {id: MetricsIds.VIEWABILITY, type: 'METRIC'},
    ];
    this.reportCurator = this.populateReportDashboard(Constants.CURRENT_MONTH, null, null, [Constants.GROUP_DAY], cols);
    this.waitReport(this.reportCurator, 'reportCuratorDashboard', this.manageReportCurator);
    this.getDashboardReport(this.reportCurator, '0', 'audienceExtension', '1', true);
  }

  getCuratorReportsPieDP() {
    // Current Month
    const cols: Column[] = [
        {id: EventsIds.CURRENCY_REVENUE_SHARE, type: 'EVENT'},
    ];
    this.reportCurator = this.populateReportDashboard(Constants.CURRENT_MONTH, null, null, [Constants.DEAMAND_PARTNERS], cols);
    this.waitReport(this.reportCurator, 'reportCuratorPieDp', this.manageReportPieDp);
    this.getDashboardReport(this.reportCurator, '0', 'audienceExtension', '1');
  }

  getCuratorReportsPieFormat() {
    // Current Month
    const cols: Column[] = [
        {id: EventsIds.CURRENCY_REVENUE_SHARE, type: 'EVENT'},
    ];
    this.reportCurator = this.populateReportDashboard(Constants.CURRENT_MONTH, null, null, [Constants.FORMAT], cols);
    this.waitReport(this.reportCurator, 'reportCuratorPieFormat', this.manageReportPieFormat);
    this.getDashboardReport(this.reportCurator, '0', 'audienceExtension', '1');
  }

  getPieReport() {
    // Pie device report
    this.reportDevice = this.populateReportDashboard(Constants.LAST_7_DAYS, null, null, [16]);
    this.waitReport(this.reportDevice, 'reportDevice', this.manageDevicePie);
    this.getDashboardReport(this.reportDevice, '0');
  }

  stopRunningReports() {
    if (this.reportPast) { this.infoService.stopReportCall(this.reportPast).subscribe(() => {}); }
    if (this.reportTodayData) { this.infoService.stopReportCall(this.reportTodayData).subscribe(() => {}); }
    if (this.reportTopDomain) { this.infoService.stopReportCall(this.reportTopDomain).subscribe(() => {}); }
    if (this.reportTopCampaign) { this.infoService.stopReportCall(this.reportTopCampaign).subscribe(() => {}); }
  }

  columnLoader() {
    const userRole: number = this.infoService.user.type;
    const userConfig: number = this.infoService.user.configurationId;
    const userEntity: number = this.infoService.user.entityId;

    // Publisher Case
    if (userRole === Constants.PUBLISHER) {
      this.myImpressionColumn = this.infoService.getColFromId(EventsIds.PAID_IMPRESSION, 'revenue', 'EVENT');
      if (userConfig === Constants.SUB_PUBLISHER) {
        this.myMoneyColumn = this.infoService.getColFromId(EventsIds.EARNINGS_VIRTUAL, 'revenue', 'EVENT');
        this.myImpressionColumn = this.infoService.getColFromId(EventsIds.PAID_IMPRESSION, 'revenue', 'EVENT');
      } else {
        this.myMoneyColumn = this.infoService.getColFromId(EventsIds.EARNINGS_VIRTUAL, 'revenue', 'EVENT');
        if (!this.infoService.getColFromId(EventsIds.PAID_IMPRESSION, 'revenue', 'EVENT')) {
          // For new pubisher dsp
          this.myImpressionColumn = this.infoService.getColFromId(EventsIds.BID_RENDER, 'revenue', 'EVENT');
        }
      }

    // Buyer Case
    } else if (userRole === Constants.BUYER) {
      this.myImpressionColumn = this.infoService.getColFromId(EventsIds.IMPRESSIONS, 'campaign', 'EVENT');
      this.myMoneyColumn = this.infoService.getColFromId(EventsIds.TOTAL_COST, 'campaign', 'EVENT');
      if (userEntity === Constants.ADYSSEUM_ENTITY_ID) {
        this.myMoneyColumn = this.infoService.getColFromId(EventsIds.CURRENCY_EARNINGS, 'campaign', 'EVENT');
      }
      if (userConfig === Constants.ADVERTISER_CONF_ID) {
        this.myMoneyColumn = this.infoService.getColFromId(EventsIds.COST_TO_CLIENT, 'campaign', 'EVENT');
      }
    }
  }

  populateReportDashboard(period: number, start?: string, end?: string, groupBy?: number[], cols?: Column[], typeReport?: number) {
    const report = new DataGenerateTable();
    report.cols = [];
    if (cols) {
      report.cols = cols;
    } else {
      report.cols.push(this.myMoneyColumn); // Money
      report.cols.push(this.myImpressionColumn); // impression
    }
    report.groupBy = groupBy ? groupBy : [2];
    report.filters = new Filter();
    report.filters.period = {id: period};
    if (period === Constants.SELECT_PERIOD && start && end) {
      report.filters.period.startDate = start;
      report.filters.period.endDate = end;
    }
    report.filters.inventoryFilter = {};
    report.filters.mediaFilter = {};
    report.filters.userFilter = {};
    report.timezone = this.infoService.user.timezone;
    if (typeReport) {
      report.new_report = typeReport;
    }
    return report;
  }

  waitReport(repo: DataGenerateTable, typeReport: string, callback: (result: string, clas: any, cals2: any) => any) {
    repo.uid = Math.abs(UtilsFun.getReportHash(this.infoService.user.id + JSON.stringify(repo) + new Date().getTime()));
    this.expectedReport[typeReport] = repo.uid;

    console.log('Start waiting for report: ' + repo.uid);

    this.timerWaitReport[repo.uid] = setInterval(() => {
      console.log('polling report response');
      this.infoService.getReportResponse(repo.uid).retry(3).subscribe(result => {
        switch (result.status) {
          case 102:
            // call next polling
            break;
          case 200:
            console.log('Stop waiting for report: ' + repo.uid);
            callback(result.report, this.infoService, this);
            clearInterval(this.timerWaitReport[repo.uid]);
            break;
          case 400:
            console.log('Error for report: ' + repo.uid + ' err: ', result.report);
            clearInterval(this.timerWaitReport[repo.uid]);
            break;
        }
      }, err => {
        console.log('Error for report: ' + repo.uid + ' err: ', err);
        clearInterval(this.timerWaitReport[repo.uid]);
      });
    }, 5000);
  }

  manageReportPast(res: any, infoService: InfoService, dashboardService: DashboardService) {
    if (res === undefined || res.length === 0) {
      dashboardService.dashboard7daysNewGraphData = null;
      dashboardService.dashboard7daysImpressionGraphData = null;
      dashboardService.dashboardPastMonthData = 0;
      dashboardService.dashboardYesterdayData = 0;
      dashboardService.dashboardCurrentMonthData = 0;
      dashboardService.dashboard30daysImpressionNewGraphData = null;
      dashboardService.dashboard30daysNewGraphData = null;
      dashboardService.dashboardPieTopDomainGraphData = null;
      dashboardService.dashboardPieTopCampaignGraphData = null;
      return;
    }
    let day: string;
    if (res[0]['Giorno']) {
      day = 'Giorno';
    } else if (res[0]['Day']) {
      day = 'Day';
    }
    const lastMonth = dashboardService.getFirstPreviousMonth(new Date()).slice(3, 5);
    const thisMonth = dashboardService.today.slice(3, 5);
    const lastDay = new Date(new Date().setDate(new Date().getDate() - 1 )).toLocaleDateString('en-GB');

    dashboardService.managePastMonthData(res.filter(elem => lastMonth === (elem[day] && elem[day].slice(3, 5))), dashboardService);
    dashboardService.manageReport30days(res.filter(elem => thisMonth === (elem[day] && elem[day].slice(3, 5))), day, dashboardService);
    dashboardService.manageLastDayData(res.filter(elem => lastDay === (elem[day])), dashboardService);
    dashboardService.manageReport7days(res.filter(elem => {
      for (let i = 0; i < ((new Date()).getDay() + 6 ) % 7; i++) {
        if (elem[day] === new Date(new Date().setDate(new Date().getDate() - (i + 1) )).toLocaleDateString('en-GB')) {
          return true;
        }
      }
      return false;
    }), day, dashboardService);
  }

   manageReportCurator(res: any, infoService: InfoService, dashboardService: DashboardService) {
     if (res === undefined || res.length === 0) {
      dashboardService.dashboardCurationSpendData = null;
      dashboardService.dashboardCurationEarningsData = null;
      dashboardService.dashboardCurationMedianCPMData = null;
      dashboardService.dashboardCurationViewabilityData = null;
      return;
    }
     const day = 'Day';

     // Organizzo le colonne
     let dealCol: ColumnValue = infoService.cols3?.find(el => el.id === EventsIds.CURRENCY_DEAL_SPEND && el.type === 'EVENT');
     let earningsCol: ColumnValue = infoService.cols3?.find(el => el.id === EventsIds.CURRENCY_REVENUE_SHARE && el.type === 'EVENT');
     let CPMCol: ColumnValue = infoService.cols3?.find(el => el.id === MetricsIds.CURRENCY_AVERAGE_CPM && el.type === 'METRIC');
     let viewabilityCol: ColumnValue = infoService.cols3?.find(el => el.id === MetricsIds.VIEWABILITY && el.type === 'METRIC');

     // Aggiungo il valore
     dealCol.value = res[0][dealCol?.label];
     earningsCol.value = res[0][earningsCol?.label];
     CPMCol.value = res[0][CPMCol?.label];
     viewabilityCol.value = res[0][viewabilityCol?.label];

     // Popolo le variabili di lettura della dashboard (quadratoni e report mese)
     dashboardService.dashboardCurationSpendData = dealCol;
     dashboardService.dashboardCurationEarningsData = earningsCol;
     dashboardService.dashboardCurationMedianCPMData = CPMCol;
     dashboardService.dashboardCurationViewabilityData = viewabilityCol;

     for (let i = 1; i < res.length; i++) {
       if (res[i][day]) {
         const g = res[i][day].split('/');
         if (g && g.length > 2) {
           res[i].myDayOfMonth = parseInt(g[0], 10);
         }
       }
     }
     const values = [];

     const month = dashboardService.todayDate.getMonth();
     const daysInThisMonth = new Date(dashboardService.todayDate.getFullYear(), month + 1, 0).getDate();
     for (let i = 0; i < daysInThisMonth; i++) {
       if (res.some(elem => elem.myDayOfMonth === (i + 1))) {
         const element = res.find(elem => elem.myDayOfMonth === (i + 1));
         values.push({
           'label': UtilsFun.pad(i + 1),
           'value': element[dealCol?.label],
           'color': ChartUtils.colors30Days[i]
         });
       } else {
         if ((i + 1) === (new Date().getDate()) && dashboardService.dashboardTodayData !== undefined &&  dashboardService.dashboardTodayImpressionData !== undefined) {
           values.push({
             'label': dashboardService.today.slice(0, 2),
             'value': dashboardService.dashboardTodayData,
             'color': ChartUtils.colors30Days[i]
           });
         } else {
           values.push({
             'label': UtilsFun.pad(i + 1),
             'value': 0,
             'color': '#ffffff'
           });
         }
       }
     }

     dashboardService.dashboardCurationCurrentMonthGraphData = {
       labels: values.map(v => v.label),
       datasets: [{
         data: values.map(v => v.value),
         backgroundColor: values.map(v => v.color)
       }]
     };
   }

  manageReportPieDp(res: any, infoService: InfoService, dashboardService: DashboardService) {
    let revenueShareCol: ColumnValue = infoService.cols3?.find(el => el.id === EventsIds.CURRENCY_REVENUE_SHARE && el.type === 'EVENT');
    const dataPie: { label: string; value: number }[] = [];
    let sum = 0;

    for (let i = 0; i < res.length; i++) {
      if (res[i] && res[i][revenueShareCol.label]) {
        const revenue = parseFloat((res[i][revenueShareCol.label] + '').replace(',', '.'));
        dataPie.push({ label: res[i][Object.keys(res[i])[0]], value: revenue });
        sum += revenue;
      }
    }
    const topDemandPartnerData = dataPie.sort((a, b) => b.value - a.value);
    const pieChartData: ChartData<'doughnut'> = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: []
      }]
    };

    if (topDemandPartnerData.length > 0) {
      pieChartData.labels = topDemandPartnerData.map(d => d.label);
      pieChartData.datasets[0].data = topDemandPartnerData.map(d => d.value / sum);
      pieChartData.datasets[0].backgroundColor = topDemandPartnerData.map((_, i) =>
        ChartUtils.colorsPie[i % ChartUtils.colorsPie.length]
      );
    } else {
      pieChartData.labels = ['No data'];
      pieChartData.datasets[0].data = [1];
      pieChartData.datasets[0].backgroundColor = [ChartUtils.colorsPie[0]];
    }
    dashboardService.dashboardCurationPieDPData = pieChartData;
  }

  manageReportPieFormat(res: any, infoService: InfoService, dashboardService: DashboardService) {
    let revenueShareCol: ColumnValue = infoService.cols3?.find(el => el.id === EventsIds.CURRENCY_REVENUE_SHARE && el.type === 'EVENT');
    const dataPie: SelectItem[] = [];
    let sum = 0;
    for (let i = 0; i < res.length; i++) {
      if (res[i] && res[i][revenueShareCol.label]) {
        const revenue = parseFloat((res[i][revenueShareCol.label] + '').replace(',', '.'));
        dataPie.push({label: res[i][Object.keys(res[i])[0]], value: revenue});
        sum = sum + dataPie[dataPie.length - 1].value;
      }
    }
    const topFormatData = dataPie.sort((a, b) => b.value - a.value);
    const pieChartData: ChartData<'doughnut'> = {
      labels: [],
      datasets: [{
        data: [],
        backgroundColor: []
      }]
    };

    if (topFormatData.length > 0) {
      pieChartData.labels = topFormatData.map(d => d.label);
      pieChartData.datasets[0].data = topFormatData.map(d => d.value / sum);
      pieChartData.datasets[0].backgroundColor = topFormatData.map((_, i) =>
        ChartUtils.colorsPieDevice[i % ChartUtils.colorsPieDevice.length]
      );
    } else {
      pieChartData.labels = ['No data'];
      pieChartData.datasets[0].data = [1];
      pieChartData.datasets[0].backgroundColor = [ChartUtils.colorsPieDevice[0]];
    }
    dashboardService.dashboardCurationPieAdFormatData = pieChartData;
  }

  // Settimana corrente
  manageReport7days(res: any[], day: string, dashboardService: DashboardService) {
    const moneyField = this.myMoneyColumn.label;
    const impressionField = dashboardService.myImpressionColumn.label;

    for (let i = 0; i < res.length; i++) {
      if (res[i][day]) {
        res[i].myDayOfWeek = DateFun.getDateFromString(res[i][day]).getDay();
      }
      if (res[i] && res[i][moneyField]) {
        res[i][moneyField] = parseFloat((res[i][moneyField] + '').replace(',', '.'));
      }
    }

    const values = [];
    const valuesImpression = [];

    for (let i = 0; i < 7; i++) {
      const numb = (i + 1) % 7;
      if (res.some(elem => elem.myDayOfWeek === numb)) {
        const element = res.find(elem => elem.myDayOfWeek === numb);
        values.push(element[moneyField] || 0);
        valuesImpression.push(parseInt(element[impressionField], 10) || 0);
      } else {
        if ((i + 1) % 7 === new Date().getDay() && dashboardService.dashboardTodayData !== undefined && dashboardService.dashboardTodayImpressionData !== undefined) {
          values.push(dashboardService.dashboardTodayData);
          valuesImpression.push(dashboardService.dashboardTodayImpressionData);
        } else {
          values.push(0);
          valuesImpression.push(0);
        }
      }
    }

    dashboardService.dashboard7daysNewGraphData = {
      labels: dashboardService.WEEK_LABELS,
      datasets: [{
        label: 'Cumulative Return',
        data: values,
        backgroundColor: ChartUtils.colors7Days
      }]
    };

    dashboardService.dashboard7daysImpressionGraphData = {
      labels: dashboardService.WEEK_LABELS,
      datasets: [{
        label: 'Impressions',
        data: valuesImpression,
        backgroundColor: ChartUtils.colorsImpression7Days
      }]
    };
  }

  manageReport30days(res: any[], day: string, dashboardService: DashboardService) {
    const moneyField = dashboardService.myMoneyColumn.label;
    const impressionField = dashboardService.myImpressionColumn.label;
    let totalMoney = 0;
    const labels: string[] = [];
    const values: number[] = [];
    const valuesImpression: number[] = [];
    const backgroundColors: string[] = [];
    const backgroundColorsImpression: string[] = [];

    if (!res || res.length === 0) {
      dashboardService.dashboardCurrentMonthData = 0;
      dashboardService.dashboard30daysNewGraphData = { labels: [], datasets: [] };
      dashboardService.dashboard30daysImpressionNewGraphData = { labels: [], datasets: [] };
      return;
    }

    for (let i = 0; i < res.length; i++) {
      if (res[i]?.[moneyField]) {
        res[i][moneyField] = parseFloat((res[i][moneyField] + '').replace(',', '.'));
        totalMoney += res[i][moneyField];
      }
      if (res[i][day]) {
        const g = res[i][day].split('/');
        if (g.length > 2) {
          res[i].myDayOfMonth = parseInt(g[0], 10);
        }
      }
    }

    const month = dashboardService.todayDate.getMonth();
    const daysInThisMonth = new Date(dashboardService.todayDate.getFullYear(), month + 1, 0).getDate();

    for (let i = 0; i < daysInThisMonth; i++) {
      const dayLabel = UtilsFun.pad(i + 1);
      labels.push(dayLabel.toString());

      if (res.some(elem => elem.myDayOfMonth === i + 1)) {
        const element = res.find(elem => elem.myDayOfMonth === i + 1);
        values.push(element[moneyField]);
        valuesImpression.push(parseInt(element[impressionField], 10));
        backgroundColors.push(ChartUtils.colors30Days[i]);
        backgroundColorsImpression.push(ChartUtils.colorsImpression30Days[i]);
      } else {
        if ((i + 1) === new Date().getDate() && dashboardService.dashboardTodayData !== undefined) {
          values.push(dashboardService.dashboardTodayData);
          valuesImpression.push(dashboardService.dashboardTodayImpressionData);
          backgroundColors.push(ChartUtils.colors30Days[i]);
          backgroundColorsImpression.push(ChartUtils.colorsImpression30Days[i]);
        } else {
          values.push(0);
          valuesImpression.push(0);
          backgroundColors.push('#ffffff');
          backgroundColorsImpression.push('#ffffff');
        }
      }
    }

    dashboardService.dashboard30daysNewGraphData = {
      labels: labels,
      datasets: [{ data: values, backgroundColor: backgroundColors, label: '€' }]
    };

    dashboardService.dashboard30daysImpressionNewGraphData = {
      labels: labels,
      datasets: [{ data: valuesImpression, backgroundColor: backgroundColorsImpression, label: 'Impressions' }]
    };

    dashboardService.dashboardCurrentMonthData = totalMoney;
  }

  managePastMonthData(res: any[], dashboardService: DashboardService) {
    const moneyField = dashboardService.myMoneyColumn.label;
    let totalMoney = 0;
    if (res === undefined || res.length === 0) {
      dashboardService.dashboardPastMonthData = 0;
      return;
    }
    for (let i = 0; i < res.length; i++) {
      if (res[i] && res[i][moneyField]) {
        res[i][moneyField] = parseFloat((res[i][moneyField] + '').replace(',', '.'));
        totalMoney += res[i][moneyField];
      }
    }
    dashboardService.dashboardPastMonthData = totalMoney;
  }

  manageLastDayData(res: any[], dashboardService: DashboardService) {
    const moneyField = dashboardService.myMoneyColumn.label;
    if (res === undefined || res.length === 0) {
      dashboardService.dashboardYesterdayData = 0;
      return;
    }
    if (res[0] && res[0][moneyField] !== undefined) {
      dashboardService.dashboardYesterdayData = parseFloat((res[0][moneyField] + '').replace(',', '.'));
    }
  }

  manageTodayData(res: string, infoService: InfoService, dashboardService: DashboardService) {
    const moneyField = dashboardService.myMoneyColumn.label;
    const impressionField = dashboardService.myImpressionColumn.label;
    if (res === undefined || res.length === 0) {
      dashboardService.dashboardTodayData = 0;
      dashboardService.dashboardTodayImpressionData = 0;
      return;
    }
    if (res[0]) {
      if (res[0][moneyField] !== undefined) {
        dashboardService.dashboardTodayData = parseFloat((res[0][moneyField] + '').replace(',', '.'));
      }
      if (res[0][impressionField] !== undefined) {
        dashboardService.dashboardTodayImpressionData = res[0][impressionField];
      }
      dashboardService.upgrade7Day(res[0], dashboardService);
      dashboardService.upgrade30Day(res[0], dashboardService);
    } else {
      dashboardService.dashboardTodayData = 0;
      dashboardService.dashboardTodayImpressionData = 0;
    }
  }

  upgrade7Day(resToday: any, dashboardService: DashboardService) {
    const numb = (new Date()).getDay();

    if (!dashboardService.dashboard7daysNewGraphData) {
      dashboardService.dashboard7daysNewGraphData = { labels: [], datasets: [{ label: 'Cumulative Return', data: [], backgroundColor: [] }] };
    }

    if (!dashboardService.dashboard7daysImpressionGraphData) {
      dashboardService.dashboard7daysImpressionGraphData = { labels: [], datasets: [{ label: 'Impressions', data: [], backgroundColor: [] }] };
    }

    const index = (numb + 6) % 7;
    const label = dashboardService.WEEK_LABELS[index];

    // Aggiornamento del dataset per New Graph Data
    dashboardService.dashboard7daysNewGraphData.labels[index] = label;
    dashboardService.dashboard7daysNewGraphData.datasets[0].data[index] = dashboardService.dashboardTodayData;
    dashboardService.dashboard7daysNewGraphData.datasets[0].backgroundColor[index] = ChartUtils.colors7Days[numb];

    // Aggiornamento del dataset per Impression Graph Data
    dashboardService.dashboard7daysImpressionGraphData.labels[index] = label;
    dashboardService.dashboard7daysImpressionGraphData.datasets[0].data[index] = dashboardService.dashboardTodayImpressionData;
    dashboardService.dashboard7daysImpressionGraphData.datasets[0].backgroundColor[index] = ChartUtils.colorsImpression7Days[numb];

    // Cloniamo gli oggetti per forzare l'aggiornamento di Angular
    const temp1 = UtilsFun.cloneField(dashboardService.dashboard7daysNewGraphData);
    dashboardService.dashboard7daysNewGraphData = null;
    dashboardService.dashboard7daysNewGraphData = temp1;

    const temp2 = UtilsFun.cloneField(dashboardService.dashboard7daysImpressionGraphData);
    dashboardService.dashboard7daysImpressionGraphData = null;
    dashboardService.dashboard7daysImpressionGraphData = temp2;
  }

  upgrade30Day(resToday: any, dashboardService: DashboardService) {
    const numb = new Date().getDate() - 1;

    if (!dashboardService.dashboard30daysNewGraphData) {
      dashboardService.dashboard30daysNewGraphData = {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], label: '€' }]
      };
    }

    if (!dashboardService.dashboard30daysNewGraphData.labels.includes(dashboardService.today.slice(0, 2))) {
      dashboardService.dashboard30daysNewGraphData.labels[numb] = dashboardService.today.slice(0, 2);
    }

    dashboardService.dashboard30daysNewGraphData.datasets[0].data[numb] = dashboardService.dashboardTodayData;
    dashboardService.dashboard30daysNewGraphData.datasets[0].backgroundColor[numb] = ChartUtils.colors30Days[numb];

    // Creiamo una copia per forzare il refresh dei dati
    const temp1 = UtilsFun.cloneField(dashboardService.dashboard30daysNewGraphData);
    dashboardService.dashboard30daysNewGraphData = null;
    dashboardService.dashboard30daysNewGraphData = temp1;

    // Stesso processo per il grafico delle impression
    if (!dashboardService.dashboard30daysImpressionNewGraphData) {
      dashboardService.dashboard30daysImpressionNewGraphData = {
        labels: [],
        datasets: [{ data: [], backgroundColor: [], label: 'Impressions' }]
      };
    }

    if (!dashboardService.dashboard30daysImpressionNewGraphData.labels.includes(dashboardService.today.slice(0, 2))) {
      dashboardService.dashboard30daysImpressionNewGraphData.labels[numb] = dashboardService.today.slice(0, 2);
    }

    dashboardService.dashboard30daysImpressionNewGraphData.datasets[0].data[numb] = dashboardService.dashboardTodayImpressionData;
    dashboardService.dashboard30daysImpressionNewGraphData.datasets[0].backgroundColor[numb] = ChartUtils.colorsImpression30Days[numb];

    // Copia per forzare il refresh
    const temp2 = UtilsFun.cloneField(dashboardService.dashboard30daysImpressionNewGraphData);
    dashboardService.dashboard30daysImpressionNewGraphData = null;
    dashboardService.dashboard30daysImpressionNewGraphData = temp2;
  }


  manageTopDomain(res: any, infoService: InfoService, dashboardService: DashboardService) {
    const moneyField = dashboardService.myMoneyColumn.label;
    dashboardService.reportTopDomain = undefined;
    const data5D: { label: string, value: number }[] = [];
    let sum = 0;

    for (let i = 0; i < res.length; i++) {
      if (res[i] && res[i][moneyField]) {
        const spent = parseFloat((res[i][moneyField] + '').replace(',', '.'));
        data5D.push({ label: res[i][Object.keys(res[i])[0]], value: spent });
        sum += spent;
      }
    }

    dashboardService.topDomainData = data5D.sort((a, b) => b.value - a.value);

    if (dashboardService.topDomainData.length > 5) {
      const top5 = dashboardService.topDomainData.slice(0, 5);
      const otherSum = dashboardService.topDomainData.slice(5).reduce((acc, curr) => acc + curr.value, 0);

      dashboardService.dashboardPieTopDomainGraphData = {
        labels: [...top5.map(item => item.label), 'Other'],
        datasets: [{
          data: [...top5.map(item => item.value / sum), otherSum / sum],
          backgroundColor: [
            ChartUtils.colorsPie[0], ChartUtils.colorsPie[1], ChartUtils.colorsPie[2],
            ChartUtils.colorsPie[3], ChartUtils.colorsPie[4], ChartUtils.colorsPie[5]
          ]
        }]
      };
    } else {
      dashboardService.dashboardPieTopDomainGraphData = {
        labels: dashboardService.topDomainData.map(item => item.label),
        datasets: [{
          data: dashboardService.topDomainData.map(item => item.value / sum),
          backgroundColor: dashboardService.topDomainData.map((_, index) => ChartUtils.colorsPie[index])
        }]
      };
    }
  }

  manageTopCampaign(res: any, infoService: InfoService, dashboardService: DashboardService) {
    dashboardService.reportTopCampaign = undefined;
    const moneyField = dashboardService.myMoneyColumn.label;
    const myData: { label: string; value: number }[] = [];
    let sum = 0;

    for (let i = 0; i < res.length; i++) {
      const gross = parseFloat((res[i][moneyField] + '').replace(',', '.'));
      myData.push({ label: res[i][Object.keys(res[i])[0]], value: gross });
      sum += gross;
    }

    // Ordina i dati in ordine decrescente
    dashboardService.topCampaignData = myData.sort((a, b) => b.value - a.value);

    // Se ci sono più di 5 elementi, raggruppa gli altri sotto "Other"
    if (dashboardService.topCampaignData.length > 5) {
      const top5 = dashboardService.topCampaignData.slice(0, 5);
      const otherSum = dashboardService.topCampaignData.slice(5).reduce((acc, curr) => acc + curr.value, 0);

      dashboardService.dashboardPieTopCampaignGraphData = {
        labels: [...top5.map(item => item.label), 'Other'],
        datasets: [{
          data: [...top5.map(item => item.value / sum), otherSum / sum],
          backgroundColor: [
            ChartUtils.colorsPie[0], ChartUtils.colorsPie[1], ChartUtils.colorsPie[2],
            ChartUtils.colorsPie[3], ChartUtils.colorsPie[4], ChartUtils.colorsPie[5]
          ]
        }]
      };
    } else {
      dashboardService.dashboardPieTopCampaignGraphData = {
        labels: dashboardService.topCampaignData.map(item => item.label),
        datasets: [{
          data: dashboardService.topCampaignData.map(item => item.value / sum),
          backgroundColor: dashboardService.topCampaignData.map((_, index) => ChartUtils.colorsPie[index])
        }]
      };
    }
  }

  manageDevicePie(res: any, infoService: InfoService, dashboardService: DashboardService) {
    dashboardService.reportDevice = undefined;
    const impressionField = dashboardService.myImpressionColumn.label;
    if (res && res.length >= 0) {
      const sum = res.map(elem => parseInt(elem[impressionField], 10)).reduce((a, b) => a + b, 0);
      dashboardService.dashboardPieDeviceGraphData = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [],
        }]
      };

      for (let i = 0; i < res.length; i++) {
        dashboardService.dashboardPieDeviceGraphData.labels.push(res[i].Device.charAt(0).toUpperCase() + res[i].Device.slice(1));
        dashboardService.dashboardPieDeviceGraphData.datasets[0].data.push(parseInt(res[i][impressionField], 10) / sum);
        dashboardService.dashboardPieDeviceGraphData.datasets[0].backgroundColor[i] = ChartUtils.colorsPieDevice[i];
      }
    } else {
      dashboardService.dashboardPieDeviceGraphData = { labels: [], datasets: [{ data: [], backgroundColor: [] }] };
    }
  }

  clearDashboard() {
    this.dashboardTodayData = undefined;
    this.dashboardTodayImpressionData = undefined;
    this.dashboardYesterdayData = undefined;
    this.dashboardCurrentMonthData = undefined;
    this.dashboardPastMonthData = undefined;
    this.dashboard7daysNewGraphData = undefined;
    this.dashboard7daysImpressionGraphData = undefined;
    this.dashboard30daysNewGraphData = undefined;
    this.dashboard30daysImpressionNewGraphData = undefined;
    this.dashboardPieTopDomainGraphData = undefined;
    this.dashboardPieTopCampaignGraphData = undefined;
    this.topCampaignData = undefined;
    this.topDomainData = undefined;
    this.dashboardPieDeviceGraphData = undefined;

    // curation data
    this.dashboardCurationSpendData = undefined;
    this.dashboardCurationEarningsData = undefined;
    this.dashboardCurationMedianCPMData = undefined;
    this.dashboardCurationViewabilityData = undefined;
    this.dashboardCurationCurrentMonthGraphData = undefined;
  }

  getLogs(type: number, conditionLog?: number, userId?: number): Observable<Log[]> {
    const params: any = {};
    if (type) {params['filterAdmin'] = type.toString(); }
    if (userId !== undefined) { params['filterUser'] = userId.toString(); }

    switch (conditionLog) {
      case 1: { params['is_server_error'] = '1'; break; }
      case 2: { params['is_server_error'] = '2'; break; }
      case 3: { params['filterSelf'] = '1'; break; }
      case 4: { params['filterAccess'] = '1'; break; }
    }
    return this.http.get<Log[]>(this.getLogsURL, {params: params});
  }

}
